/** 
 * 2019-2020 Konrad Kubala <000konrad000@gmail.com>
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
import $ from "jquery";
const useForm = () => {
  const { prestashop } = window;
  const { visiblePassword: visiblePasswordMap } = prestashop.themeSelectors;
  const visiblePasswordList = document.querySelectorAll(visiblePasswordMap.visiblePassword);
  if (visiblePasswordList.length > 0) {
    visiblePasswordList.forEach((visiblePasswordInput) => {
      const visiblePasswordBtn = visiblePasswordInput == null ? void 0 : visiblePasswordInput.nextElementSibling;
      visiblePasswordBtn == null ? void 0 : visiblePasswordBtn.addEventListener("click", () => {
        const visiblePasswordIcon = visiblePasswordBtn.firstElementChild;
        let type = visiblePasswordInput.getAttribute("type");
        let typeIcon = "visibility";
        if (type === "password") {
          type = "text";
          typeIcon = "visibility_off";
        } else {
          type = "password";
        }
        visiblePasswordInput.setAttribute("type", type);
        if (visiblePasswordIcon) {
          visiblePasswordIcon.innerHTML = typeIcon;
        }
      });
    });
  }
};
export default useForm;
