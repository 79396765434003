/** 
 * 2019-2020 Konrad Kubala <000konrad000@gmail.com>
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
import $ from "jquery";
import prestashop from "prestashop";
import TouchSpin from "./components/TouchSpin";
const $body = $("body"), $facetedsearchContainer = $("#js-product-list-container, #ps-facetedsearch-modal .modal-body");
let productModal = null;
let flagAddProducts = false;
$(() => {
  $body.on("change", "#search_filters, #sort-orders", resetFilters);
  $body.on("click", ".js-search-link", resetFilters);
  prestashop.on("clickQuickView", renderQuickView);
  prestashop.on("updateProductList", updateProductList);
});
const showQuickView = (resp) => {
  $body.append(resp.quickview_html);
  TouchSpin.createSpin();
  $(`#quickview-modal-${resp.product.id}-${resp.product.id_product_attribute}`).modal("show");
};
const renderQuickView = (elm) => {
  let data = {
    action: "quickview",
    id_product: elm.dataset.idProduct,
    id_product_attribute: elm.dataset.idProductAttribute
  };
  $.ajax({
    url: prestashop.urls.pages.product,
    data,
    dataType: "json",
    method: "POST",
    success: showQuickView
  });
};
const updateProductList = (data) => {
  if (flagAddProducts) {
    $(".js-product-list").append($(data.rendered_products).children());
  } else {
    $(".js-product-list").replaceWith(data.rendered_products);
  }
  $(".js-facets").replaceWith(data.rendered_facets);
  $(".js-active-filters").replaceWith(data.rendered_active_filters);
  $(".js-product-list-top").replaceWith(data.rendered_products_top);
  $(".js-product-list-bottom").replaceWith(data.rendered_products_bottom);
  $(".js-product-list-header").replaceWith(data.rendered_products_header);
  psShowHide();
  $facetedsearchContainer.isLoaded();
};
const resetFilters = (event) => {
  flagAddProducts = $(event.target).hasClass("js-load-more");
  prestashop.emit("updateFacets", parseSearchUrl(event.target));
  event.preventDefault();
};
function parseSearchUrl(target) {
  return target.href || target.value || target.dataset.searchUrl || $(target).parent()[0].dataset.searchUrl;
}
function psShowHide() {
  $(".ps-shown-by-js").show();
  $(".ps-hidden-by-js").hide();
}
