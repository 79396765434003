/** 
 * 2019-2020 Konrad Kubala <000konrad000@gmail.com>
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
import $ from "jquery";
(function($2) {
  $2.fn.isLoad = function() {
    this.addClass("is--loading");
    return this;
  };
  $2.fn.isLoaded = function() {
    this.removeClass("is--loading");
    return this;
  };
})(jQuery);
