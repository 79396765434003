/** 
 * 2019-2020 Konrad Kubala <000konrad000@gmail.com>
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
import $ from "jquery";
import prestashop from "prestashop";
import Utils from "./components/Utils";
const $body = $("body");
$(() => {
  $body.on("change keyup", "#quantity_wanted", handleChangeQtyWanted);
  prestashop.on("updatedProduct", handleUpdatedProduct);
});
const handleUpdatedProduct = (event) => {
  let minProductQty = { min: parseInt(event.product_minimal_quantity, 10) };
  $("#quantity_wanted").trigger("touchspin.updatesettings", minProductQty);
  $(".js-product-images-modal").replaceWith(event.product_images_modal);
};
const handleChangeQtyWanted = (event) => {
  $(event.currentTarget).trigger("touchspin.stopspin");
  prestashop.emit("updateProduct", {
    eventType: "updatedProductQuantity",
    event
  });
};
