const ENABLE_NAVIGATOR_SHARE = 0;
const shareButton = document.querySelectorAll(".btn-share"), shareModal = document.getElementById("modal-share"), shareUrl = document.querySelectorAll(".btn-copy-share");
const shareButtonClick = (event) => {
  if (navigator.share && ENABLE_NAVIGATOR_SHARE) {
    navigator.share({
      url: prestashop.urls.current_url
    }).then(() => {
      console.log("Thanks for sharing!");
    }).catch(console.error);
  } else {
    let productId = event.target.getAttribute("data-product-id");
    let modalId = event.target.getAttribute("data-modal-id");
    $(`#modal-share-${modalId}`).modal("show");
  }
};
const shareUrlClick = (event) => {
  event.target.getAttribute("data-share-url");
  if (document.execCommand("copy")) {
    copy.innerText = "{l s='Copied' d='Shop.Theme.Actions'}";
    setTimeout(() => {
      input.blur();
      copy.innerText = "{l s='Copy' d='Shop.Theme.Actions'}";
    }, 3e3);
  }
};
const initShareButtons = () => {
  shareButton.forEach((button) => {
    button.addEventListener("click", shareButtonClick);
  });
  shareUrl == null ? void 0 : shareUrl.forEach((button) => {
    button.addEventListener("click", shareUrlClick);
  });
};
export default initShareButtons;
