const changeSelection = (event) => {
  const card = event.target.closest(".card");
  const container = card.closest(".row");
  const cardsInContainer = container.querySelectorAll(".card");
  cardsInContainer.forEach((card2) => {
    card2.classList.remove("selected");
  });
  card.classList.add("selected");
};
const useCardSelector = () => {
  const cards = document.querySelectorAll("label.card");
  cards.forEach((card) => card.addEventListener("click", changeSelection));
};
export default useCardSelector;
