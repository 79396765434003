/** 
 * 2019-2020 Konrad Kubala <000konrad000@gmail.com>
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
import $ from "jquery";
import prestashop from "prestashop";
const $body = $("body");
() => {
  prestashop.on("updatedDeliveryForm", handleUpdatedDeliveryForm);
  $body.on("click", ".js-terms a", handleClickTermsLink);
  $body.on("click", ".js-gift-checkbox", handleClickGiftCheckbox);
};
const handleUpdatedDeliveryForm = (event) => {
  if (typeof event.deliveryOption === "undefined" || 0 === event.deliveryOption.length) {
    return;
  }
  $(".carrier-extra-content").slideUp();
  event.deliveryOption.find(".carrier-extra-content").slideDown();
};
const handleClickGiftCheckbox = () => {
  $("#gift").slideToggle();
};
const ajaxTermsSuccess = (content) => {
  $("#modal").find(".js-modal-content").html($(content).find(".page-content--cms").contents());
  $("#modal .modal-body .js-modal-content").isLoaded();
};
const ajaxTermsError = (resp) => {
  prestashop.emit("handleError", { eventType: "clickTerms", resp });
};
const handleClickTermsLink = (event) => {
  event.preventDefault();
  $("#modal .modal-body .js-modal-content").isLoad();
  let url = $(event.target).attr("href");
  if (url) {
    $.ajax({
      url: url += `?content_only=1`,
      method: "GET",
      success: ajaxTermsSuccess,
      error: ajaxTermsError
    });
  }
  $("#modal").modal("show");
};
