/** 
 * 2019-2020 Konrad Kubala <000konrad000@gmail.com>
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
const useTouchSpin = () => {
  const { prestashop } = window;
  const selectors = prestashop.themeSelectors.touchspin;
  const touchspin = window.document.querySelectorAll(selectors.default);
  if (touchspin.length > 0) {
    touchspin.forEach(createSpinner);
  }
  function createSpinner(spinner) {
    const increment = spinner.querySelector(selectors.increment), decrement = spinner.querySelector(selectors.decrement), input = spinner.querySelector(selectors.input);
    increment.addEventListener("click", () => {
      input.value++;
      input.dispatchEvent(new Event("change"));
    });
    decrement.addEventListener("click", () => {
      input.value--;
      input.dispatchEvent(new Event("change"));
    });
  }
  ;
};
export default useTouchSpin;
