const useBootstrapCompability = () => {
  $("[data-target]").each(function() {
    $(this).attr("data-bs-target", $(this).attr("data-target"));
    $(this).attr("data-bs-toggle", $(this).attr("data-toggle"));
  });
  $("[data-dismiss]").each(function() {
    $(this).attr("data-bs-dismiss", $(this).attr("data-dismiss"));
  });
  $("[data-parent]").each(function() {
    $(this).attr("data-bs-parent", $(this).attr("data-parent"));
  });
};
export default useBootstrapCompability;
