/** 
 * 2019-2020 Konrad Kubala
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
() => {
  var $searchWidget = $(".search-widgets");
  var $searchBox = $searchWidget.find("input[type=text]");
  var searchURL = $searchWidget.attr("data-search-controller-url");
  var $clearButton = $searchWidget.find("i.clear");
  $("#ps-searchbar-modal").on("shown.bs.modal", function() {
    $("#search-input").trigger("focus");
  });
  $.widget("prestashop.psBlockSearchAutocomplete", $.ui.autocomplete, {
    _renderItem: function(ul, product) {
      var image = product.cover ? product.cover : prestashop.urls.no_picture_image;
      return $("<li>").append(
        $("<a>").addClass("product-line").append(
          $("<section>").addClass("product-line-image").append($('<img class="autocomplete-thumbnail" src="' + image.bySize.cart_default.url + '">'))
        ).append(
          $("<section>").addClass("product-line-info").append($("<span>").html(product.name).addClass("product-line-name")).append($("<span>").html(" (" + product.reference + ")").addClass("product-line-reference")).append($("<div>").html(product.price).addClass("product-line-price"))
        )
      ).appendTo(ul);
    }
  });
  $searchBox.psBlockSearchAutocomplete({
    appendTo: ".search-results",
    source: function(query, response) {
      $.post(searchURL, {
        s: query.term,
        resultsPerPage: 10
      }, null, "json").then(function(resp) {
        response(resp.products);
      }).fail(response);
    },
    select: function(event, ui) {
      var url = ui.item.url;
      window.location.href = url;
    }
  }).psBlockSearchAutocomplete("widget").addClass("searchbar-autocomplete");
  $(window).on("resize", function() {
    $searchBox.psBlockSearchAutocomplete();
    $searchBox.trigger("keyup");
  });
  $clearButton.on("click", function() {
    $searchBox.val("");
    $clearButton.hide();
  });
  $searchBox.on("keyup", function() {
    $clearButton.toggle($searchBox.val() !== "");
  });
};
