/** 
 * 2019-2020 Konrad Kubala <000konrad000@gmail.com>
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
import { debounce } from "debounce";
import $ from "jquery";
const cartContainer = $(".js-cart"), checkoutButton = $(".js-checkout-button"), promises = [];
let hasError = false;
let errorMsg = "";
prestashop.cart = prestashop.cart || {};
prestashop.cart.active_inputs = null;
const cart = () => {
  const { prestashop: prestashop2 } = window;
  prestashop2.on("updateCart", handleUpdateCartEvent);
  prestashop2.on("updatedCart", handleUpdatedCartEvent);
  prestashop2.on("handleError", handleError);
  updateEvents();
};
function updateEvents(delay = 250) {
  const { prestashop: prestashop2 } = window;
  const selectors = prestashop2.themeSelectors.cart;
  const cartInput = window.document.querySelectorAll(selectors.qtyInput.input);
  if (cartInput) {
    cartInput.forEach((input) => input.addEventListener("change", debounce(handleSendCartRequest, delay)));
  }
}
function getQtyDiff(target) {
  const crntValue = parseInt(target.getAttribute("value"));
  const trgtValue = parseInt(target.value);
  if (isNaN(trgtValue) || trgtValue < 1) {
    target.value = crntValue;
  }
  return trgtValue - crntValue;
}
const requestData = {
  "updateProductQuantity": (target) => {
    let quantity = getQtyDiff(target);
    return {
      ajax: 1,
      action: "update",
      qty: Math.abs(quantity),
      op: quantity > 0 ? "up" : "down"
    };
  },
  "deleteFromCart": () => {
    return {
      ajax: 1,
      action: "update"
    };
  },
  "productRefresh": (target) => {
    return {
      ajax: 1,
      action: "productrefresh",
      quantity_wanted: target.value
    };
  },
  "addDiscount": (target) => {
    return {
      ajax: 1,
      action: "update",
      addDiscount: 1,
      token: target.data("token"),
      discount_name: target.data("code")
    };
  },
  "deleteDiscount": () => {
    return {
      ajax: "1",
      action: "update",
      deleteDiscount: 1
    };
  }
};
const sendCartRequestSuccess = (resp) => {
  qtyOperations.checkUpdateOpertation(resp);
  if (!hasError) {
    prestashop.emit("updateCart", { resp });
  } else {
    prestashop.emit("handleError", { eventType: "updateProductQuantityInCart", resp });
  }
};
const sendCartRequestError = (resp) => {
  prestashop.emit("handleError", { eventType: "updateProductQuantityInCart", resp });
};
const handleSendCartRequest = (event) => {
  let target = event.target;
  let action = target.getAttribute("data-action");
  $.ajax({
    url: target.getAttribute("href") || target.getAttribute("data-url"),
    data: requestData[action](target),
    dataType: "json",
    method: "POST",
    success: sendCartRequestSuccess,
    error: sendCartRequestError
  });
  event.preventDefault();
};
const handleUpdateCartEvent = (event) => {
  document.activeElement.blur();
  cartContainer.isLoad();
  $(".quick-view").modal("hide");
};
const handleUpdatedCartEvent = (event) => {
  updateEvents();
  cartContainer.isLoaded();
  qtyOperations.switchErrorStat();
};
const handleError = () => {
  document.activeElement.blur();
  cartContainer.isLoaded();
  qtyOperations.switchErrorStat();
};
const qtyOperations = {
  "switchErrorStat": () => {
    if (errorMsg) {
      $("#notifications").append($(qtyOperations.renderNotify(errorMsg)));
      $(".toast:not(.hide)").toast("show");
      errorMsg = "";
      checkoutButton.toggleClass("disabled", !hasError);
    } else if (!hasError) {
      checkoutButton.removeClass("disabled");
    }
  },
  "checkUpdateOpertation": (resp) => {
    let errors = resp.errors || "";
    errorMsg = errors instanceof Array ? errors.join(" ") : errors;
    hasError = resp.hasOwnProperty("hasError");
  },
  "renderNotify": (errorMsg2) => {
    return `
    <div
      class="toast"
      role="alert" 
      data-alert="info"
      data-autohide="true"
      data-delay="10000"
      aria-live="assertive" 
      aria-atomic="true"
    >
      <div class="toast-body d-flex alert-primary">
        <div>${errorMsg2}</div>
        <button type="button" class="close" data-bs-dismiss="toast" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    `;
  }
};
export default cart;
