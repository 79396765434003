/** 
 * 2019-2020 Konrad Kubala <000konrad000@gmail.com>
 * 
 * NOTICE OF LICENSE
 * 
 * This source file is subject to the AFL-3.0
 * that is bundled with this package in the file LICENSE.txt.
 * 
 * @author    Konrad Kubala <000konrad000@gmail.com>
 * @copyright 2007-2020 Konrad Kubala <000konrad000@gmail.com>
 * @license   AFL-3.0
 */
import selectorsMap from "./constants/selectorsMap";
import initEmitter from "./prestashop";
import "popper.js/dist/popper.min";
import "bootstrap-latest";
import lazysizes from "lazysizes";
import zoom from "jquery-zoom";
import useForm from "./components/Form";
import useCarousel from "./components/Carousel";
import useTouchSpin from "./components/TouchSpin";
import useCardSelector from "./components/CardSelector";
import useDisabledElement from "./components/DisabledElement";
import useBootstrapCompability from "./components/bootstrap-compability";
import "./modules/productcomments";
import "./modules/ps_searchbar";
import "./modules/ps_shoppingcart";
import initShareButtons from "./modules/ps_sharebuttons";
import "./checkout";
import "./customer";
import "./listing";
import "./product";
import initCart from "./cart";
initEmitter();
window.prestashop.themeSelectors = selectorsMap;
$(() => {
  const { prestashop } = window;
  initShareButtons();
  useForm();
  useCarousel();
  useTouchSpin();
  useCardSelector();
  useDisabledElement();
  useBootstrapCompability();
  initCart();
  $(".toast").toast("show");
  window.addEventListener("scroll", bodyScrollEvent);
  prestashop.on("updatedCart", () => useTouchSpin());
});
const bodyScrollEvent = (e) => {
  const isScrolled = window.scrollY > 0;
  document.body.classList.toggle("scrolled", isScrolled);
};
